import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Colors from "../assets/theme/colors";
import Styles from "../assets/theme/styles";
import useScrollFadeIn from "./useScrollFadeIn";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ServiceRedWave from "../assets/image/service/red-wave.png";
import ServiceEnergy1 from "../assets/image/service/serviceBox/web-service-energy1.png";
import ServiceEnergy2 from "../assets/image/service/serviceBox/web-service-energy2.png";
import ServiceEnergy3 from "../assets/image/service/serviceBox/web-service-energy3.png";
import ServiceLife1 from "../assets/image/service/serviceBox/web-service-life1.png";
import ServiceLife2 from "../assets/image/service/serviceBox/web-service-life2.png";
import ServiceLife3 from "../assets/image/service/serviceBox/web-service-life3.png";
import WebServiceBigWaveImg from "../assets/image/service/web-service-big-wave.png";
import MobileServiceBigWaveImg from "../assets/image/service/mobile-service-big-wave.png";

import CustomServiceSlide from "../components/CustomServiceSlide";

const ServiceContainer = styled(Styles.Container)`
  background-color: ${Colors.Primary_Navy};
  justify-content: center;

  @media all and (min-width: 1200px) {
    height: 1817px;
  }

  @media all and (max-width: 1199px) {
    height: 871px;
  }
`;

const ServiceContents = styled(Styles.Container)`
  flex-direction: column;
  align-items: center;
`;

const ServiceTitleText = styled(Styles.Text)`
  @media all and (min-width: 1200px) {
    font-weight: 700;
    font-size: 44px;
    line-height: 52.8px;
    margin-top: 175px;
  }

  @media all and (max-width: 1199px) {
    font-weight: 700;
    font-size: 24px;
    line-height: 28.8px;
    margin-top: 60px;
  }
`;

const RedWaveImg = styled.img`
  width: 40px;
  height: 4px;
  margin-top: 50px;
  margin-left: 50px;
`;

const ServiceBoxSmallTitle = styled(Styles.Text)`
  font-weight: 400;
  font-size: 24px;
  line-height: 28.8px;
  margin-top: 14px;
  margin-left: 50px;
`;

const ServiceBoxTitle = styled(Styles.Text)`
  font-weight: 700;
  font-size: 34px;
  line-height: 47px;
`;

const ServiceBoxTitleWrap = styled.div`
  margin-top: 42px;
  margin-left: 50px;
`;

const ServiceContentWrap = styled(Styles.ServiceBoxWrap)`
  @media all and (max-width: 1199px) {
    display: none;
  }
`;

const ServiceWrap = styled.div`
  display: flex;
  flex-direction: column;

  @media all and (min-width: 1200px) {
    width: 1200px;
  }

  @media all and (max-width: 1199px) {
    width: 375px;
  }
`;

const ServiceBoxWrap = styled.div`
  width: 650px;
  height: 307px;
  margin-top: 64px;
  margin-left: 50px;
  justify-content: space-between;

  & > img {
    margin-right: 24px;
  }

  & > img:last-child {
    margin-right: 0px;
  }
`;

const ServiceBoxImg = styled.img`
  width: 200.67px;
  height: 307px;
  image-rendering: -webkit-optimize-contrast;
  transform: translateZ(0);
  backface-visibility: hidden;
`;

const WebServiceBigWave = styled.img`
  position: absolute;
  mix-blend-mode: color-dodge;
  opacity: 0.7;
  width: 720.84px;
  height: 385.52px;
  margin-top: 100px;

  @media all and (max-width: 1199px) {
    display: none;
  }
`;

const MobileServiceBigWave = styled.img`
  position: absolute;
  mix-blend-mode: color-dodge;
  opacity: 0.7;
  width: 257px;
  height: 165px;
  margin-top: 42px;

  @media all and (min-width: 1200px) {
    display: none;
  }
`;

const SliderWrap = styled.div`
  margin-top: 40px;
  width: 323px;
  margin-left: 26px;

  @media all and (min-width: 1200px) {
    display: none;
  }
`;

const TabWrap = styled.div`
  width: 237px;
  height: 33px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  color: ${Colors.white};

  & > div:nth-child(1) {
    cursor: pointer;
    font-weight: ${(props) => (props.active === 0 ? 700 : 400)};
    background-color: ${(props) =>
      props.active === 0 ? "rgba(255, 255, 255, 0.1)" : ""};
    backdrop-filter: ${(props) => (props.active === 0 ? "blur(32px)" : "")};
    border-radius: ${(props) => (props.active === 0 ? "10px" : "")};
  }
  & > div:nth-child(2) {
    cursor: pointer;
    font-weight: ${(props) => (props.active === 1 ? 700 : 400)};
    background-color: ${(props) =>
      props.active === 1 ? "rgba(255, 255, 255, 0.1)" : ""};
    backdrop-filter: ${(props) => (props.active === 1 ? "blur(32px)" : "")};
    border-radius: ${(props) => (props.active === 1 ? "10px" : "")};
  }
`;
const Text = styled(Styles.Text)`
  height: 17px;
  align-items: center;
  text-align: center;
  font-size: 14px;
  line-height: 17px;
  padding: 8px 16px 8px 16px;
`;

const ServiceContent = () => {
  const [tabActive, setTabActive] = useState(0);
  const [serviceTitle, setServiceTitle] = useState("");

  useEffect(() => {
    if (tabActive === 0) {
      setServiceTitle(
        "도시가스 안전 서비스 이용 고객 대상\n에너지 안전 솔루션 서비스"
      );
    } else if (tabActive === 1) {
      setServiceTitle("고객의 삶을 더욱 편리하게 하는\n생활 밀착형 서비스");
    }
  }, [tabActive]);

  const serviceTitle1 =
    "도시가스 안전 서비스 이용 고객 대상\n에너지 안전 솔루션 서비스";
  const serviceTitle2 = "고객의 삶을 더욱 편리하게 하는\n생활 밀착형 서비스";

  const animatedItem1 = useScrollFadeIn(0.3, "0s");
  const animatedItem2 = useScrollFadeIn(0.3, "0s");
  const animatedItem3 = useScrollFadeIn(0.3, "1.8s");
  const animatedItem4 = useScrollFadeIn(0.3, "1.4s");

  const settings = {
    dots: false,
    infinite: true,
    fade: true,
    speed: 300,
    slidesToShow: 1,
    draggable: false,
    beforeChange: (current, next) => setTabActive(next),
    arrows: false,
  };

  const handleTabChange = (tab) => {
    setTabActive(tab);
  };

  return (
    <ServiceContainer>
      <ServiceWrap>
        <WebServiceBigWave src={WebServiceBigWaveImg} {...animatedItem3} />
        <MobileServiceBigWave
          src={MobileServiceBigWaveImg}
          {...animatedItem4}
        />
        <ServiceContents {...animatedItem1}>
          <ServiceTitleText>사업 소개</ServiceTitleText>
          <ServiceContentWrap>
            <RedWaveImg src={ServiceRedWave} />
            <ServiceBoxSmallTitle>에너지 서비스</ServiceBoxSmallTitle>
            <ServiceBoxTitleWrap>
              {serviceTitle1.split("\n").map((line, index) => {
                return (
                  <ServiceBoxTitle key={index}>
                    {line}
                    <br />
                  </ServiceBoxTitle>
                );
              })}
            </ServiceBoxTitleWrap>
            <ServiceBoxWrap>
              <ServiceBoxImg src={ServiceEnergy1} />
              <ServiceBoxImg src={ServiceEnergy2} />
              <ServiceBoxImg src={ServiceEnergy3} />
            </ServiceBoxWrap>
          </ServiceContentWrap>
          <ServiceContentWrap>
            <RedWaveImg src={ServiceRedWave} />
            <ServiceBoxSmallTitle>라이프 서비스</ServiceBoxSmallTitle>
            <ServiceBoxTitleWrap>
              {serviceTitle2.split("\n").map((line, index) => {
                return (
                  <ServiceBoxTitle key={index}>
                    {line}
                    <br />
                  </ServiceBoxTitle>
                );
              })}
            </ServiceBoxTitleWrap>
            <ServiceBoxWrap>
              <ServiceBoxImg src={ServiceLife1} />
              <ServiceBoxImg src={ServiceLife2} />
              <ServiceBoxImg src={ServiceLife3} />
            </ServiceBoxWrap>
          </ServiceContentWrap>
        </ServiceContents>
        <SliderWrap {...animatedItem2}>
          <TabWrap active={tabActive}>
            <Text
              onClick={() => {
                handleTabChange(0);
                Slider.slider.slickGoTo(0);
              }}
            >
              에너지 서비스
            </Text>
            <Text
              onClick={() => {
                handleTabChange(1);
                Slider.slider.slickGoTo(1);
              }}
            >
              라이프 서비스
            </Text>
          </TabWrap>
          <Slider ref={(slider) => (Slider.slider = slider)} {...settings}>
            <CustomServiceSlide id="energy" title={serviceTitle} />
            <CustomServiceSlide id="life" title={serviceTitle} />
          </Slider>
        </SliderWrap>
      </ServiceWrap>
    </ServiceContainer>
  );
};

export default ServiceContent;
