import React from 'react'
import styled from 'styled-components'
import Colors from '../assets/theme/colors'
import Styles from '../assets/theme/styles'
import Animation from '../assets/theme/animation'

import WebMainBigWaveImg from '../assets/image/main/web-main-big-wave.png'
import WebMainSmallWaveImg from '../assets/image/main/web-main-small-wave.png'
import MobileBigWaveImg from '../assets/image/main/mobile-main-big-wave.png'
import MobileSmallWaveImg from '../assets/image/main/mobile-main-small-wave.png'
import MainArrowDown from '../assets/image/main/arrow-down.png'
import CsImg from '../assets/image/main/headset.png'

const MainContainer = styled(Styles.Container)`
  background-color: ${Colors.Primary_Navy};
  justify-content: center;
`

const MainWrap = styled.div`
  @media all and (min-width: 1200px) {
    justify-content: center;
    width: 1200px;
    height: 775px;
  }

  @media all and (max-width: 1199px) {
    display: flex;
    width: 375px;
    height: 655px;
    flex-direction: column;
  }
`

const WebBigWaveImg = styled.img`
  position: absolute;
  width: 496px;
  height: 189px;
  margin-top: 60px;
  margin-left: 672px;
  image-rendering: -webkit-optimize-contrast;
  transform: translateZ(0);
  backface-visibility: hidden;

  @media all and (max-width: 1199px) {
    display: none;
  }

  animation: ${Animation.FadeIn} 1.5s 0.8s backwards;
`

const MobileMainBigWaveImg = styled.img`
  position: absolute;
  width: 228px;
  height: 120px;
  margin-top: 95px;
  margin-left: 145px;
  image-rendering: -webkit-optimize-contrast;
  transform: translateZ(0);
  backface-visibility: hidden;

  @media all and (min-width: 1200px) {
    display: none;
  }

  animation: ${Animation.FadeIn} 1.5s 0.8s backwards;
`

const MobileMainSmallWaveImg = styled.img`
  position: absolute;
  width: 187px;
  height: 88.4px;
  margin-top: 310px;
  image-rendering: -webkit-optimize-contrast;
  transform: translateZ(0);
  backface-visibility: hidden;

  @media all and (min-width: 1200px) {
    display: none;
  }

  animation: ${Animation.FadeIn} 1.5s 0.8s backwards;
`

const MainTextWrap = styled.div`
  position: absolute;

  @media all and (min-width: 1200px) {
    display: flex;
    width: 690px;
    height: 207px;
    margin-top: 313px;
    margin-left: 120px;
    flex-direction: column;
  }

  @media all and (max-width: 1199px) {
    width: 360px;
    margin-top: 239px;
    margin-left: 24px;
  }

  animation: ${Animation.FadeIn} 1.5s 0.8s backwards;
`

const MainText = styled(Styles.Text)`
  font-weight: 300;

  @media all and (min-width: 1200px) {
    font-size: 50px;
    line-height: 69px;
    padding-right: 8px;
  }

  @media all and (max-width: 1199px) {
    font-size: 26px;
    line-height: 34px;
    padding-right: 4px;
  }
`

const MainTextBold = styled(Styles.Text)`
  font-weight: 800;

  @media all and (min-width: 1200px) {
    font-size: 50px;
    line-height: 69px;
    padding-right: 8px;
  }

  @media all and (max-width: 1199px) {
    font-size: 26px;
    line-height: 34px;
    padding-right: 4px;
  }
`

const Span = styled.span`
  display: flex;
   

  @media all and (min-width: 1200px) {
    &:first-child {
      padding-left: 8px;
    }

    &:first-child>div:nth-child(2) {
      padding-right: 0px;
    }
  }

  @media all and (max-width: 1199px) {
    &:first-child {
      padding-left: 4px;
    }

    &:first-child>div:nth-child(2) {
      padding-right: 0px;
    }
  }
`

const WebSmallWaveImg = styled.img`
  position: absolute;
  width: 383px;
  height: 147px;
  margin-top: 447px; 
  margin-right: 820px;
  image-rendering: -webkit-optimize-contrast;
  transform: translateZ(0);
  backface-visibility: hidden;

  @media all and (max-width: 1199px) {
    display: none;
  }

  animation: ${Animation.FadeIn} 1.5s 0.8s backwards;
`

const MobileCsImg = styled.img`
  width: 32px;
  height: 32px;
  margin-top: 14px;
`

const CsButton = styled.div`
  cursor: pointer;
  position: absolute;
  width: 60px;
  height: 60px;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0px 0px 12px rgba(255, 255, 255, 0.12);
  border-radius: 40px;
  margin-top: 565px;
  margin-left: 287px;
  text-align: center;

  @media all and (min-width: 1200px) {
    display: none;
  }

  animation: ${Animation.FadeIn} 1.5s 0.8s backwards;
`

const WebArrowDownImg = styled.img`
  display: flex;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;

  @media all and (min-width: 1200px) {
    width: 24px;
    height: 24px;
    margin-top: 707px;   
  }

  @media all and (max-width: 1199px) {
    display: none;
  }

  animation: ${Animation.FadeIn} 1.5s 0.8s backwards;
`

const MobileArrowDownImg = styled.img`
  display: flex;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;

  @media all and (min-width: 1200px) {
    display: none;
  }

  @media all and (max-width: 1199px) {
    width: 20px;
    height: 20px;
    margin-top: 600px;   
  }

  animation: ${Animation.FadeIn} 1.5s 0.8s backwards;
`


const MainContent = () => {
  const mainTitle = '사람들이 \b가장 기본적인 서비스\b를\n\b쉽고 간단하게\b 누릴 수 있도록\n\b세상을 바꿔나갑니다\b'

  const ScrollTo = (y) => {
    window.scrollTo({top: y, behavior: 'smooth'})
  }

  return (
    <MainContainer>
      <MainWrap>
        <WebBigWaveImg src={WebMainBigWaveImg} />
        <WebSmallWaveImg src={WebMainSmallWaveImg} />
        <MobileMainBigWaveImg src={MobileBigWaveImg} />
        <MobileMainSmallWaveImg src={MobileSmallWaveImg} />
        <MainTextWrap>
          {
            mainTitle.split('\n').map((line, index) => {
              return (<Span key={index}>{
                line.split('\b').map((boldFont, index) => {
                  if (index % 2 === 1) {
                    return (<MainTextBold key={index}>{boldFont}</MainTextBold>)
                  } else {
                    return (<MainText key={index}>{boldFont}</MainText>)
                  }
                })
              }<br/></Span>)
            })
          }
        </MainTextWrap>
        <MobileArrowDownImg
          onClick={() => {
            ScrollTo(670)
          }} 
          src={MainArrowDown} 
        />
        <WebArrowDownImg
          onClick={() => {
            ScrollTo(750)
          }} 
          src={MainArrowDown} 
        />
        <CsButton 
          onClick={() => {
            ScrollTo(2150)
        }}>
          <MobileCsImg src={CsImg} />
        </CsButton>
      </MainWrap>
    </MainContainer>
  )
}

export default MainContent