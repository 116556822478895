import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import Colors from '../assets/theme/colors'
import Styles from '../assets/theme/styles'

import SmallMarker from '../assets/image/map-pin.png'
import { SITEMAP } from '../constant'

const Container = styled.div`
  height: 500px;
`

const WebMapContainer = styled.div`
  border-radius: 8px;
  margin-top: 16px;
  width: 872px;
  height: 375px;

  @media all and (max-width: 1199px) {
    display: none;
  }
`

const MobileMapContainer = styled.div`
  border-radius: 8px;
  margin-top: 16px;
  width: 333px;
  height: 333px;

  @media all and (min-width: 1200px) {
    display: none;
  }
`

const AddrContainer = styled(Styles.Text)`
  display: flex;
  background-color: ${Colors.light_grey};
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media all and (min-width: 1200px) {
    padding: 16px 34px;
    margin-top: 20px;
  }

  @media all and (max-width: 1199px) {
    margin-top: 16px;
    padding: 14px 34px;
  }
`

const AddrText = styled(Styles.Text)`
  font-weight: 400;
  color: ${Colors.Primary_Navy};

  @media all and (min-width: 1200px) {
    font-size: 16px;
    line-height: 23.2px;
  }

  @media all and (max-width: 1199px) {
    font-size: 12px;
    line-height: 17.4px;
  }
`

const SmallMarkerImg = styled.img`
  margin-right: 8px;

  @media all and (min-width: 1200px) {
    width: 16px;
    height: 16px;
    margin-top: 3.5px;
  }

  @media all and (max-width: 1199px) {
    width: 12px;
    height: 12px;
    margin-top: 2.5px;
  }
`

const AddrWrap = styled.div`
  display: flex;
`

const { google } = window

const CustomMapSilde = ({id, latitude, longitude, address}) => {
  const webMapRef = useRef(null)
  const mobileMapRef = useRef(null)

  const [isMobile, setisMobile] = useState(false)

  const resizingHandler = () => {
    if (window.innerWidth <= 1200) {
      setisMobile(true)
    } else {
      setisMobile(false)
    }
  }

  useEffect(() => {
    if (window.innerWidth <= 1200) {
      setisMobile(true)
    }
    
    window.addEventListener("resize", resizingHandler)
    return () => {
      window.removeEventListener("resize", resizingHandler)
    }
  }, [])

  const getGoogleMapRef = () => {
    if (google) {
      const mapAreaKey = `${!isMobile ? 'web' : 'mobile'}-map`
      const mapElt = document.getElementById(mapAreaKey)
      const activeTabInfo = SITEMAP.INFO.find(info => info.key === id)
      const position = activeTabInfo ? activeTabInfo : { lat: SITEMAP.INFO[0].lat, lng: SITEMAP.INFO[0].lng }
      const mapConfig = { center: position, zoom: 16 }

      const map = new google.maps.Map(mapElt, mapConfig)
      new google.maps.Marker({
        position: position,
        map: map
      })

      if (isMobile) mobileMapRef.current = map
      else webMapRef.current = map
    } else {
      console.error(':: Please Check Google Map API Authorization.')
    }
  }

  useEffect(() => {
    getGoogleMapRef()
  }, [isMobile])

  // 웹 마커 생성
  useEffect(() => {
    if (google) {
      if (webMapRef.current) {
        new google.maps.Marker({
          position: { lat: latitude, lng: longitude },
          map: webMapRef.current
        })
        webMapRef.current.setCenter({ lat: latitude, lng: longitude })
      }
    } else {
      console.error(':: Please Check Google Map API Authorization.')
    }
  }, [
    id,
    latitude,
    longitude
  ])

  // 모바일 마커 생성
  useEffect(() => {
    if (google) {
      if (mobileMapRef.current) {
        new google.maps.Marker({
          position: { lat: latitude, lng: longitude },
          map: mobileMapRef.current
        })
        mobileMapRef.current.setCenter({ lat: latitude, lng: longitude })
      }
    } else {
      console.error(':: Please Check Google Map API Authorization.')
    }
  }, [
    id,
    latitude,
    longitude
  ])

  return (
    <Container>
      <WebMapContainer id={`web-map`} />
      <MobileMapContainer id={`mobile-map`} />
      <AddrContainer>
        <AddrWrap>
          <SmallMarkerImg src={SmallMarker} />
          <AddrText>{address}</AddrText>
        </AddrWrap>
      </AddrContainer>
    </Container>
  )
}

export default CustomMapSilde