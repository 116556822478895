import React from 'react'
import styled from 'styled-components'

import MainContent from './MainContent'
import VisionContent from './VisionContent'
import ServiceContent from './ServiceContent'
import CsContent from './CsContent'

const ContentsWrap = styled.div`
  width: 100%;
  margin: 0;
  margin-top: 70px;
`

const Contents = () => {
  return (
    <ContentsWrap>
      <MainContent />
      <VisionContent />
      <ServiceContent />
      <CsContent />
    </ContentsWrap>
  )
}

export default Contents