import React from "react";
import styled, { css } from "styled-components";
import Colors from "../assets/theme/colors";
import useDetectClose from "./useDetectClose";

const Wrapper = styled.div`
  margin-left: 340px;
  display: relative;
  width: 150px;
  height: 20px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: ${Colors.white};

  @media all and (max-width: 1199px) {
    margin: 8px 0 -12px 0;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
  }
`;

const DropdownContainer = styled.div`
  position: relative;
  text-align: center;
`;

const DropdownButton = styled.div`
  cursor: pointer;
  color: rgb(255, 255, 255);
  font-family: Pretendard;
  font-style: normal;
  line-height: 18px;
  font-weight: 400;
  font-size: 12px;
  opacity: 0.8;
`;

const Menu = styled.div`
  font-family: Pretendard;
  font-style: normal;
  background-color: #001a3e;
  position: absolute;
  top: -245px;
  left: 50%;
  width: 150px;
  text-align: center;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, -20px);
  transition: visibility 0.4s;
  z-index: 9;

  ${({ isDropped }) =>
    isDropped &&
    css`
      opacity: 1;
      visibility: visible;
      transform: translate(-50%, 0);
      left: 50%;
    `};
`;

const Ul = styled.ul`
  list-style-type: none;
  padding: 0px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: start;

  li {
    padding: 10px 0 10px 8px;
    font-size: 12px;
  }
  li a {
    text-decoration: none;
    color: white;
  }

  li:hover:not(.none) {
    background-color: ${Colors.blue};
  }

  li span {
    color: ${Colors.blue};
    font-weight: 700;
  }
`;

const DropdownMenu = () => {
  const [dropIsOpen, dropRef, dropHandler] = useDetectClose(false);

  return (
    <Wrapper>
      <DropdownContainer>
        <DropdownButton onClick={dropHandler} ref={dropRef}>
          관련법령 바로가기 &nbsp;&nbsp;&#8594;
        </DropdownButton>
        <Menu isDropped={dropIsOpen}>
          <Ul>
            <li className="none">
              <span>소비자기본법 및 시행령</span>
            </li>
            <li>
              <a
                href="https://www.law.go.kr/법령/소비자기본법"
                target="_blank"
                rel="noopener noreferrer"
              >
                소비자기본법 법률
              </a>
            </li>
            <li>
              <a
                href="https://www.law.go.kr/법령/소비자기본법시행령"
                target="_blank"
                rel="noopener noreferrer"
              >
                소비자기본법 시행령
              </a>
            </li>

            <li className="none">
              <span>도시가스사업법</span>
            </li>
            <li>
              <a
                href="https://www.law.go.kr/법령/도시가스사업법"
                target="_blank"
                rel="noopener noreferrer"
              >
                도시가스사업법 법률
              </a>
            </li>
            <li>
              <a
                href="https://www.law.go.kr/법령/도시가스사업법시행령"
                target="_blank"
                rel="noopener noreferrer"
              >
                도시가스사업법 시행령
              </a>
            </li>
            <li>
              <a
                href="https://www.law.go.kr/법령/도시가스사업법시행규칙"
                target="_blank"
                rel="noopener noreferrer"
              >
                도시가스사업법 시행규칙
              </a>
            </li>
          </Ul>
        </Menu>
      </DropdownContainer>
    </Wrapper>
  );
};

export default DropdownMenu;
