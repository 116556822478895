import React from "react";
import { useState } from "react";
import styled from "styled-components";
import Styles from "../assets/theme/styles";
import Colors from "../assets/theme/colors";
import ServiceRedWave from "../assets/image/service/red-wave.png";
import ServiceEnergy1 from "../assets/image/service/modal/easySimple_01.svg";
import ServiceEnergy2 from "../assets/image/service/modal/easySimple_02.svg";
import ServiceEnergy3 from "../assets/image/service/modal/easySimple_03.svg";
import ServiceEnergy4 from "../assets/image/service/modal/easySimple_04.svg";

const ModalBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  z-index: 9999;
`;

const RedWaveImg = styled.img`
  width: 40px;
  height: 4px;
  margin-top: 50px;
  margin-left: 50px;

  @media all and (max-width: 1199px) {
    margin-top: 20px;
  }
`;

const Overlay = styled.div`
  transform: translate(-50%, -50%) scale(1);
  position: absolute;
  left: 0%;
  right: 0%;
  top: 70%;
  bottom: 0%;
`;

const CloseSection = styled.div`
  width: 750px;
  height: 37px;
  background: linear-gradient(to left, black, rgb(5, 42, 93));

  @media all and (max-width: 1199px) {
    width: 450px;
    height: 37px;
  }
`;

const CloseButton = styled.div`
  float: right;
  cursor: pointer;
  display: flex;
  color: ${Colors.white};
  margin: -3px 12px 0 0;
  i {
    font-family: Pretendard;
    font-style: normal;
    font-size: 30px;
    font-weight: 200;
  }
`;

const ModalWrap = styled.div`
  width: 750px;
  height: 1060px;
  background: #00265a;
  position: absolute;
  top: 106%;
  left: 100%;
  transform: translate(-50%, -50%);
  background-color: Linear;
  border: 1px solid #b7b7b7;
  h1 {
    display: flex;
    font-family: NanumSquare, Pretendard;
    font-size: 31px;
    font-weight: 700;
    margin: 25px auto;
    text-align: center;
    color: ${Colors.white};
    justify-content: center;
  }

  @media all and (max-width: 1199px) {
    width: 450px;
    height: 700px;
    background: #00265a;
    position: absolute;
    top: 62%;
    left: 100%;
    transform: translate(-50%, -50%);
    background-color: Linear;
    border: 1px solid #b7b7b7;

    h1 {
      display: flex;
      font-size: 20px;
      font-weight: 700;
      margin: 10px auto;
      text-align: center;
      color: ${Colors.white};
      justify-content: center;
    }
  }
`;

const Contents = styled.div`
  width: 500px;
  height: 761px;
  margin: 0 auto;
  background: linear-gradient(to left, #1e4070, #052a5d);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 13px;

  @media all and (max-width: 1199px) {
    width: 400px;
    height: 577px;
    margin: 0 auto;
    background: linear-gradient(to left, #1e4070, #052a5d);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
`;

const ServiceBoxWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  gap: 20px;
  justify-content: center;
  @media all and (max-width: 1199px) {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    gap: 30px;
    justify-content: center;
  }
`;

const ServiceBoxImg = styled.img`
  width: 200px;
  height: 236px;
  position: relative;
  overflow: hidden;
  object-fit: scale-down;
  background: linear-gradient(to right, #1e4070, #052a5d);
  border-radius: 13px;

  @media all and (max-width: 1199px) {
    width: 136px;
    height: 176px;
    position: relative;
    overflow: hidden;
    object-fit: none;
  }
`;

const ServiceBoxTitleWrap = styled.div`
  margin-top: 20px;
  margin-left: 50px;

  @media all and (max-width: 1199px) {
    margin-top: 8px;
  }
`;

const ServiceBoxTitle = styled(Styles.Text)`
  font-family: NanumSquare, Pretendard;
  font-style: bold;
  font-size: 23px;
  font-weight: 700;
  line-height: 30px;

  @media all and (max-width: 1199px) {
    font-size: 13px;
    font-weight: 700;
  }
`;

const ServiceBoxSmallTitle = styled(Styles.Text)`
  font-family: NanumSquare, Pretendard;
  font-style: normal;
  font-size: 18px;
  font-weight: 400;
  line-height: 28.8px;
  margin-top: 3px;
  margin-left: 50px;

  @media all and (max-width: 1199px) {
    margin-top: 1px;
  }
`;

function Modal1(props) {
  const [modalOpen] = useState(true);
  const modalClose = () => {
    props.onClose(0);
  };

  const serviceTitle3 = "소비자의 삶과 편의를 위한\n소비자 중심 경영";

  return (
    <>
      {modalOpen && (
        <ModalBg>
          <Overlay>
            <ModalWrap>
              <CloseSection>
                <CloseButton onClick={modalClose}>
                  <i>x</i>
                </CloseButton>
              </CloseSection>
              <h1>경영방침 / 가치체계</h1>
              <Contents>
                <RedWaveImg src={ServiceRedWave} />
                <ServiceBoxSmallTitle>Easy & Simple</ServiceBoxSmallTitle>
                <ServiceBoxTitleWrap>
                  {serviceTitle3.split("\n").map((line, index) => {
                    return (
                      <ServiceBoxTitle key={index}>
                        {line}
                        <br />
                      </ServiceBoxTitle>
                    );
                  })}
                </ServiceBoxTitleWrap>
                <ServiceBoxWrap>
                  <ServiceBoxImg src={ServiceEnergy1} />
                  <ServiceBoxImg src={ServiceEnergy2} />
                  <ServiceBoxImg src={ServiceEnergy3} />
                  <ServiceBoxImg src={ServiceEnergy4} />
                </ServiceBoxWrap>
              </Contents>
            </ModalWrap>
          </Overlay>
        </ModalBg>
      )}
    </>
  );
}

export default Modal1;
