import React from 'react'
import styled from 'styled-components'
import Colors from '../assets/theme/colors'
import Styles from '../assets/theme/styles'
import useScrollFadeIn from './useScrollFadeIn'

import CsTabs from './CsTabs'

import Headset from '../assets/image/headset.png'

const CsContainer = styled(Styles.Container)`
  background-color: ${Colors.Sub_Grey};

  @media all and (min-width: 1200px) {
    height: 1223px;
  }

  @media all and (max-width: 1199px) {
    height: 850px;
  }
`

const CsContents = styled(Styles.Container)`
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const CsTitle = styled(Styles.Text)`
  color: ${Colors.Primary_Navy};
  font-weight: 700;

  @media all and (min-width: 1200px) {
    font-size: 44px;
    line-height: 53px;
    margin-top: 175px;
  }

  @media all and (max-width: 1199px) {
    font-size: 24px;
    line-height: 28.8px;
    margin-top: 60px;
  }
`

const CsNumberWrap = styled.a`
  background-color: ${Colors.light_grey};
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  text-decoration: none;

  @media all and (min-width: 1200px) {
    margin-top: 55px;
    width: 434px;
    height: 147px;
  }

  @media all and (max-width: 1199px) {
    margin-top: 40px;
    width: 265px;
    height: 95px;
  }
`

const HeadsetImg = styled.img`

  @media all and (min-width: 1200px) {
    width: 64px;
    height: 64px;
    margin-left: 40px;
    margin-top: 32px;
    margin-right: 20px;
  }

  @media all and (max-width: 1199px) {
    width: 40px;
    height: 40px;
    margin-left: 24px;
    margin-top: 19px;
    margin-right: 12px;
  }
`

const CsNumber = styled(Styles.Text)`
  color: ${Colors.Primary_Navy};
  font-weight: 600;

  @media all and (min-width: 1200px) {
    font-size: 50px;
    line-height: 60px;
  }

  @media all and (max-width: 1199px) {
    font-size: 30px;
    line-height: 36px;
  }
`

const CsNumberDesc = styled(Styles.Text)`
  font-weight: normal;
  color: rgba(0, 38, 90, 0.6);

  @media all and (min-width: 1200px) {
    font-size: 16px;
    line-height: 19px;
  }

  @media all and (max-width: 1199px) {
    font-size: 12px;
    line-height: 14px;
    margin-top: 5px;
  }
`

const CsNumberTextWrap = styled.div`
  flex-direction: column;

  @media all and (min-width: 1200px) {
    width: 273px;
    margin-top: 34px;
  }

  @media all and (max-width: 1199px) {
    width: 165px;
    margin-top: 19px;
  }
`

const ServiceText = styled(Styles.Text)`
  font-weight: 700;
  font-size: 18px;
  line-height: 21.6px;
  color: ${Colors.Primary_Navy};
  margin-top: 56px;

  @media all and (min-width: 1200px) {
    display: none;
  }
`

const CsContent = () => {

  const animatedItem = useScrollFadeIn(0.4, '0s')

  return (
    <CsContainer>
      <CsContents {...animatedItem}>
        <CsTitle>고객센터</CsTitle>
        <CsNumberWrap href='tel:1666-0009'>
          <HeadsetImg src={Headset} />
          <CsNumberTextWrap>
            <CsNumber>1666-0009</CsNumber>
            <CsNumberDesc>평일 09:00 - 18:00 / 주말 &#183; 공휴일 휴무</CsNumberDesc>
          </CsNumberTextWrap>
        </CsNumberWrap>
        <ServiceText>서비스센터</ServiceText>
        <CsTabs />
      </CsContents>
    </CsContainer>
  )
}

export default CsContent