import styled from 'styled-components'
import Colors from './colors'

const Text = styled.div`
  color: ${Colors.white};
  font-family: Pretendard;
  font-style: normal;
`

const Container = styled.div`
  display: flex;
  width: 100%;
`

const ServiceBoxWrap = styled.div`
  width: 750px;
  height: 652px;
  border-radius: 8px;
  backdrop-filter: blur(32px);
  background: linear-gradient(122.58deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.02) 100%);
  margin-top: 55px;
`

const Styles = ({
  Text,
  Container,
  ServiceBoxWrap
})

export default Styles