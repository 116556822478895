import React from 'react'
import styled from 'styled-components'
import Styles from '../assets/theme/styles'

import ServiceRedWave from '../assets/image/service/red-wave.png'
import ServiceEnergy1 from '../assets/image/service/serviceBox/mobile-service-energy1.png'
import ServiceEnergy2 from '../assets/image/service/serviceBox/mobile-service-energy2.png'
import ServiceEnergy3 from '../assets/image/service/serviceBox/mobile-service-energy3.png'
import ServiceLife1 from '../assets/image/service/serviceBox/mobile-service-life1.png'
import ServiceLife2 from '../assets/image/service/serviceBox/mobile-service-life2.png'
import ServiceLife3 from '../assets/image/service/serviceBox/mobile-service-life3.png'

const Container = styled.div`
  width: 323px;
  height: 585px;
  background: linear-gradient(159.05deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.02) 92.71%);
  backdrop-filter: blur(32px);
  border-radius: 8px;
  margin-top: 40px;
  padding-top: 40px;
`

const RedWaveImg = styled.img`
  width: 40px;
  height: 4px;
  margin-left: 24px;
`

const ServiceBoxTitleWrap = styled.div`
  margin-top: 16px;
  margin-left: 24px;
`

const ServiceBoxTitle = styled(Styles.Text)`
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
`

const ServiceBoxWrap = styled.div`
  padding-left: 24px;
  margin-top: 32px;
`

const ServiceBoxImg = styled.img`
  width: 275px;
  height: 137px;
  margin-bottom: 16px;
  image-rendering: -webkit-optimize-contrast;
  transform: translateZ(0);
  backface-visibility: hidden;
`

const CustomServiceSlide = ({id, title}) => {

  return (
    <Container>
      <RedWaveImg src={ServiceRedWave} />
      <ServiceBoxTitleWrap>
        {
          title.split('\n').map((line, index) => {
            return (<ServiceBoxTitle key={index}>{line}<br/></ServiceBoxTitle>)
          })
        }
      </ServiceBoxTitleWrap>
      <ServiceBoxWrap>
        <ServiceBoxImg src={id === 'energy' ? ServiceEnergy1 : ServiceLife1} />
        <ServiceBoxImg src={id === 'energy' ? ServiceEnergy2 : ServiceLife2} />
        <ServiceBoxImg src={id === 'energy' ? ServiceEnergy3 : ServiceLife3} />
      </ServiceBoxWrap>
    </Container>
  )
}

export default CustomServiceSlide