import { keyframes } from 'styled-components'

const FadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const Animation = ({
  FadeIn
})

export default Animation