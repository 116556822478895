import React from 'react'
import styled from 'styled-components'
import Colors from '../assets/theme/colors'
import Styles from '../assets/theme/styles'
import useScrollFadeIn from './useScrollFadeIn'

import VisionImg from '../assets/image/vision-img.jpg'

const VisionContainer = styled(Styles.Container)`
  margin-top: -1px;
  margin-bottom: -1px;
  background-color: ${Colors.Primary_Navy};
  overflow: hidden;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  right: 0;
  width: 100%;
  top: 0;
  background-size: 1200px 800px;
  background-image: url(${VisionImg});
  display: flex;
  justify-content: center;

  @media all and (min-width: 1200px) {
    height: 800px;
  }

  @media all and (max-width: 1199px) {
    height: 611px;
    background-position: 36% 0%;
  }
`

const VisionText = styled(Styles.Text)`
  font-weight: 700;

  @media all and (min-width: 1200px) {
    font-size: 50px;
    line-height: 58px;
  }

  @media all and (max-width: 1199px) {
    font-size: 28px;
    line-height: 33.6px;
  }
`

const WebVisionTextWrap1 = styled.div`
  position: absolute;

  margin-top: 80px;
  margin-left: 70px;

  @media all and (max-width: 1199px) {
    display: none;
  }
`

const MobileVisionTextWrap1 = styled.div`
  position: absolute;

  @media all and (min-width: 1200px) {
    display: none;
  }

  @media all and (max-width: 1199px) {
    width: 207px;
    margin-top: 56px;
    margin-left: 24px;
  }
`

const VisionTextWrap2 = styled.div`
  text-align: right;

  @media all and (min-width: 1200px) {
    margin-top: 594px;    
    margin-right: 110px;    
  }

  @media all and (max-width: 1199px) {
    margin-top: 477px;    
    margin-right: 24px;    
  }
`

const VisionWrap = styled.div`

@media all and (min-width: 1200px) {
    width: 1200px;
  }

  @media all and (max-width: 1199px) {
    width: 375px;
  }
`

const VisionContent = () => {

  const webVisionTitle1 = '일상의 행복은\n에너넷과 연결될 때 시작됩니다'
  const mobileVisionTitle1 = '일상의 행복은\n에너넷과 연결될 때\n시작됩니다'

  const visionTitle2 = '오늘 해야할 일을\n더욱 안전하고 편리하게'

  const animatedItem1 = useScrollFadeIn(0.7, '0s')
  const animatedItem2 = useScrollFadeIn(0.7, '0s')
  const animatedItem3 = useScrollFadeIn(0.7, '0s')

  return (
    <VisionContainer>
      <VisionWrap>
        <WebVisionTextWrap1 {...animatedItem1}>
            {
              webVisionTitle1.split('\n').map((line, index) => {
                return (<span key={index}><VisionText>{line}</VisionText><br/></span>)
              })
            }
        </WebVisionTextWrap1>  
        <MobileVisionTextWrap1 {...animatedItem2}>
            {
              mobileVisionTitle1.split('\n').map((line, index) => {
                return (<span key={index}><VisionText>{line}</VisionText><br/></span>)
              })
            }
        </MobileVisionTextWrap1>  
        <VisionTextWrap2 {...animatedItem3}>
            {
              visionTitle2.split('\n').map((line, index) => {
                return (<span key={index}><VisionText>{line}</VisionText><br/></span>)
              })
            }
        </VisionTextWrap2>
      </VisionWrap>
    </VisionContainer>
  )
}

export default VisionContent