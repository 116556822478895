import { useRef, useCallback, useEffect } from 'react'

const useScrollFadeIn = (thresholdValue, delay) => {
  const dom = useRef()

  const handleScroll = useCallback(([entry]) => {
    const { current } = dom

    if (entry.isIntersecting) {
      current.style.transitionProperty = 'opacity transform';
      current.style.transitionDuration = '2s';
      current.style.transitionTimingFunction = 'cubic-bezier(0, 0, 0.2, 1)';
      current.style.transitionDelay = delay;
      current.style.opacity = 1;
      current.style.transform = 'translate3d(0, 0, 0)';
    }
  }, [delay])

  useEffect(() => {
    let observer
    const { current } = dom

    if (current) {
      observer = new IntersectionObserver(handleScroll, { threshold: thresholdValue })
      observer.observe(current)

      return () => observer && observer.disconnect()
    }
  }, [handleScroll, thresholdValue])

  return {
    ref: dom,
    style: {
      opacity: 0,
      transform: 'translate3d(0, 0, 0)',
    }
  }
}

export default useScrollFadeIn