import React from "react";
import { useState } from "react";
import styled from "styled-components";
import Colors from "../assets/theme/colors";
import ServiceEnergy1 from "../assets/image/service/modal2/실천선언문.svg";

const ModalBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  z-index: 9999;
`;

const Overlay = styled.div`
  position: absolute;
  left: 0%;
  right: 0%;
  top: 70%;
  bottom: 0%;
`;

const CloseSection = styled.div`
  width: 750px;
  height: 37px;
  background: linear-gradient(to left, black, rgb(5, 42, 93));

  @media all and (max-width: 1199px) {
    width: 450px;
    height: 37px;
  }
`;

const CloseButton = styled.div`
  float: right;
  cursor: pointer;
  display: flex;
  color: ${Colors.white};
  margin: -3px 12px 0 0;
  i {
    font-family: Pretendard;
    font-style: normal;
    font-size: 30px;
    font-weight: 200;
  }
`;

const ModalWrap = styled.div`
  width: 750px;
  height: 900px;
  background: #00265a;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #b7b7b7;

  @media all and (max-width: 1199px) {
    width: 450px;
    height: 400px;
    background: #00265a;
    position: absolute;
    top: 12%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: Linear;
    border: 1px solid #b7b7b7;
  }
`;

const ServiceBoxImg = styled.img`
  background: white;
  width: 750px;

  @media all and (max-width: 1199px) {
    width: 450px;
  }
`;

function Modal3(props) {
  const [modalOpen] = useState(true);
  const modalClose = () => {
    props.onClose(0);
  };

  return (
    <>
      {modalOpen && (
        <ModalBg>
          <Overlay>
            <ModalWrap>
              <CloseSection>
                <CloseButton onClick={modalClose}>
                  <i>x</i>
                </CloseButton>
              </CloseSection>
              <ServiceBoxImg src={ServiceEnergy1} />
            </ModalWrap>
          </Overlay>
        </ModalBg>
      )}
    </>
  );
}

export default Modal3;
