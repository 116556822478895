const SITEMAP_KIND = {
  CENTER: "center",
  EAST: "east",
  WEST: "west",
  SOUTH: "south",
  NORTH: "north",
};

const SITEMAP_INFO = [
  {
    key: SITEMAP_KIND.CENTER,
    lat: 36.3849235,
    lng: 127.379018,
    address: "대전광역시 유성구 대덕대로582, 옥토빌딩 2층",
    title: "중부서비스센터",
  },
  {
    key: SITEMAP_KIND.EAST,
    lat: 36.31614795338591,
    lng: 127.44112263990588,
    address: "대전광역시 동구 계족로10, 일신빌딩 401호",
    title: "동부서비스센터",
  },
  {
    key: SITEMAP_KIND.WEST,
    lat: 36.375790686171285,
    lng: 127.31541741107264,
    address: "대전광역시 유성구 은구비로2 명우빌딩 501호",
    title: "서부서비스센터",
  },
  {
    key: SITEMAP_KIND.SOUTH,
    lat: 36.301277880054286,
    lng: 127.36675056874094,
    address: "대전광역시 서구 정림로10 금양빌딩 3층",
    title: "남부서비스센터",
  },
  {
    key: SITEMAP_KIND.NORTH,
    lat: 36.42660294526189,
    lng: 127.38893709757981,
    address: "대전광역시 유성구 테크노4로 98-8 평원빌딩 2층",
    title: "북부서비스센터",
  },
];

const SITEMAP_MOBILE_INFO = (() => {
  return SITEMAP_INFO.map((info) => ({
    ...info,
    title: info.title ? info.title.replace("서비스센터", "") : "",
  }));
})();

export default {
  KIND: SITEMAP_KIND,
  INFO: SITEMAP_INFO,
  M_INFO: SITEMAP_MOBILE_INFO,
};
