import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Colors from "../assets/theme/colors";
import Styles from "../assets/theme/styles";
import Collapse from "@material-ui/core/Collapse";
import Animation from "../assets/theme/animation";

import LogoImg from "../assets/image/logo.png";
import Menu from "../assets/image/header/menu.png";
import MenuClose from "../assets/image/header/menu-close.png";

const HeaderWrap = styled.div`
  width: 100%;
  margin: 0;
  position: fixed;
  z-index: 1;
`;

const Container = styled(Styles.Container)`
  background-color: ${Colors.Primary_Navy};
  justify-content: space-between;
  border-bottom: ${(props) =>
    props.visible > 10 ? "1px solid rgba(255, 255, 255, 0.15)" : ""};

  @media all and (min-width: 1200px) {
    justify-content: center;
    height: 93px;
  }

  @media all and (max-width: 1199px) {
    display: flex;
    justify-content: center;
    height: 73px;
    position: relative;
    z-index: 1;
  }
`;

const Logo = styled.img`
  cursor: pointer;

  @media all and (min-width: 1200px) {
    margin-top: 25px;
    margin-right: 700px;
    height: 40px;
    width: 122px;
  }

  @media all and (max-width: 1199px) {
    margin-top: 20px;
    margin-right: 214px;
    height: 33px;
    width: 99.89px;
  }

  animation: ${Animation.FadeIn} 1.7s;
`;

const ButtonWrap = styled.div`
  display: flex;
  margin-top: 28px;
  flex-direction: row;
  height: 37px;
  width: 248px;
  justify-content: space-between;
  align-items: center;

  @media all and (max-width: 1199px) {
    display: none;
  }

  animation: ${Animation.FadeIn} 1.7s;
`;

const WebText = styled(Styles.Text)`
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
`;

const MobileText = styled(Styles.Text)`
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  margin-right: 300px;
`;

const Button = styled(Styles.Text)`
  cursor: pointer;
  font-weight: 800;
  font-size: 14px;
  line-height: 21px;
  padding: 8px 16px 8px 16px;
  background-color: ${Colors.blue};
  border-radius: 10px;
`;

const MobileWrap = styled.div`
  display: flex;
  margin-top: 29.5px;

  @media all and (min-width: 1200px) {
    display: none;
  }
`;

const MenuButton = styled.img`
  width: 20px;
  height: 14px;
  alt: "menu";
  cursor: pointer;

  animation: ${Animation.FadeIn} 1.7s;
`;

const MenuCloseButton = styled.img`
  width: 14px;
  height: 14px;
  padding-left: 3px;
  padding-right: 3px;
  alt: "menuClose";
  cursor: pointer;
`;

const NavBar = styled.ul`
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0;
  height: 184px;
  background: ${Colors.Primary_Navy};
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.16);
  border-top: ${(props) =>
    props.visible ? "1px solid rgba(255, 255, 255, 0.15)" : ""};
  backdrop-filter: blur(13px);
  margin-top: 0;
  z-index: 1;
  display: flex;
  align-items: center;

  @media all and (min-width: 1200px) {
    display: none;
  }
`;

const NavLi = styled.li`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 26px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 40px;
  width: 100vw;
  justify-content: center;
`;

const BlurBackGround = styled.div`
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(15px);
  height: 100vh;
`;

const Header = () => {
  let width = document.body.offsetWidth;
  const [scrollTop, setScrollTop] = useState(0);
  const [toggle, setToggle] = useState(true);

  const onToggle = () => {
    width = document.body.offsetWidth;
    if (width <= 1199) setToggle(!toggle);
    else setToggle(true);
  };

  const ScrollTo = (y) => {
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      setScrollTop(e.srcElement.scrollingElement.scrollTop);
    });
  }, []);

  return (
    <HeaderWrap>
      <Container visible={scrollTop}>
        <Logo onClick={() => ScrollTo(0)} src={LogoImg} />
        <MobileWrap>
          {toggle ? (
            <MenuButton src={Menu} onClick={onToggle} />
          ) : (
            <MenuCloseButton src={MenuClose} onClick={onToggle} />
          )}
        </MobileWrap>
        <ButtonWrap>
          <WebText onClick={() => ScrollTo(0)}>메인</WebText>
          <WebText onClick={() => ScrollTo(1600)}>사업소개</WebText>
          <Button onClick={() => ScrollTo(3450)}>고객센터</Button>
        </ButtonWrap>
      </Container>
      <Collapse in={!toggle}>
        <NavBar visible={!toggle}>
          <div>
            <NavLi
              onClick={() => {
                ScrollTo(1280);
                setToggle(!toggle);
              }}
            >
              <MobileText>사업소개</MobileText>
            </NavLi>
            <NavLi
              onClick={() => {
                ScrollTo(2150);
                setToggle(!toggle);
              }}
            >
              <MobileText>고객센터</MobileText>
            </NavLi>
          </div>
        </NavBar>
        <BlurBackGround />
      </Collapse>
    </HeaderWrap>
  );
};

export default Header;
