import React from "react";
import styled from "styled-components";
import Colors from "../assets/theme/colors";
import Styles from "../assets/theme/styles";
import DropdownMenu from "./DropdownMenu";
import LogoImg from "../assets/image/logo.png";
import DropDownLink from "./DropDownLink";
import DropDownBtn from "./DropDownBtn";

const FooterWrap = styled.div`
  width: 100%;
  margin: 0;
`;

const Container = styled(Styles.Container)`
  background-color: ${Colors.Primary_Navy};

  @media all and (min-width: 1200px) {
    height: 230px;
    justify-content: center;
  }

  @media all and (max-width: 1199px) {
    height: 363px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Logo = styled.img`
  width: 88px;
  height: 28.85px;

  @media all and (min-width: 1200px) {
    margin-top: 49px;
    margin-right: 72px;
  }

  @media all and (max-width: 1199px) {
    margin-top: 60px;
    margin-right: 180px;
  }
`;

const CopyrightWrap = styled.div`
  @media all and (min-width: 1200px) {
    margin-top: 49px;
  }

  @media all and (max-width: 1199px) {
    margin-top: 44px;
  }

  & > div:nth-child(1) > div:nth-child(1) {
    cursor: pointer;
  }
  & > div:nth-child(1) > div:nth-child(3) {
    cursor: pointer;
  }
`;

const Text = styled(Styles.Text)`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  opacity: 0.8;
`;

const TextBetweenBorder = styled.div`
  height: 13px;
  width: 10px;
  margin-right: 10px;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
`;

const TextWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .duple {
    display: flex;
  }

  @media all and (max-width: 1199px) {
    display: block;
  }
`;

const TextWrapT = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const WebDescTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 385px;
  height: 58px;
  margin-top: 14px;
  margin-bottom: 14px;

  @media all and (max-width: 1199px) {
    display: none;
  }
`;

const MobileDescTextWrap = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;

  @media all and (min-width: 1200px) {
    display: none;
  }
`;

const Footer = () => {
  return (
    <FooterWrap>
      <Container>
        <Logo src={LogoImg} />
        <CopyrightWrap>
          <TextWrap>
            <div className="duple">
              <Text onClick={() => window.open("/pdf/privacy.pdf", "_blank")}>
                개인정보처리방침
              </Text>
              <TextBetweenBorder />
              <Text onClick={() => window.open("/pdf/terms-use.pdf", "_blank")}>
                이용약관
              </Text>
              <TextBetweenBorder />
              <DropdownMenu />
            </div>
            {/* <DropDownLink /> */}
            <DropDownBtn />
          </TextWrap>
          <WebDescTextWrap>
            <TextWrapT>
              <Text>(주)에너넷</Text>
              <TextBetweenBorder />
              <Text>대표자명 : 신승업</Text>
              <TextBetweenBorder />
              <Text>사업자등록번호 : 841-88-01072</Text>
            </TextWrapT>
            <TextWrap>
              <Text>주소 : 대전광역시 유성구 대덕대로582, 옥토빌딩 2층</Text>
            </TextWrap>
            <TextWrap>
              <Text>대표번호 : 042-584-3631</Text>
              <TextBetweenBorder />
              <Text>이메일 : enernet@enernet.co.kr</Text>
            </TextWrap>
          </WebDescTextWrap>
          <MobileDescTextWrap>
            <TextWrapT>
              <Text>(주)에너넷</Text>
              <TextBetweenBorder />
              <Text>대표자명 : 신승업</Text>
            </TextWrapT>
            <TextWrap>
              <Text>사업자등록번호 : 841-88-01072</Text>
            </TextWrap>
            <TextWrap>
              <Text>주소 : 대전광역시 유성구 대덕대로582, 옥토빌딩 2층</Text>
            </TextWrap>
            <TextWrap>
              <Text>대표번호 : 042-584-3631</Text>
            </TextWrap>
            <TextWrap>
              <Text>이메일 : enernet@enernet.co.kr</Text>
            </TextWrap>
          </MobileDescTextWrap>
          <TextWrap>
            <Text>Copyright ⓒ 2022 enernet. All Rights Reserved.</Text>
          </TextWrap>
        </CopyrightWrap>
      </Container>
    </FooterWrap>
  );
};

export default Footer;
