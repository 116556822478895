import React from "react";
import { useState } from "react";
import styled, { css } from "styled-components";
import Colors from "../assets/theme/colors";
import useDetectClose from "./useDetectClose";
import Modal1 from "./Modal1";
import Modal2 from "./Modal2";
import Modal3 from "./Modal3";

const Wrapper = styled.div`
  margin: 0 auto;
  display: contents;
  color: ${Colors.white};
`;

const DropdownContainer = styled.div`
  position: relative;
  text-align: center;
`;

const DropdownButton = styled.div`
  cursor: pointer;
  color: rgb(255, 255, 255);
  font-family: Pretendard;
  font-style: normal;
  line-height: 18px;
  font-weight: 400;
  font-size: 12px;
  opacity: 0.8;
  &:hover {
    color: ${Colors.blue};
    width: 100%;
  }
`;

const Menu = styled.div`
  background: rgb(212, 221, 226);
  position: absolute;
  top: 32px;
  left: 50%;
  width: 100px;
  text-align: center;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, -20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  z-index: 9;

  &:after {
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    top: -3px;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 12px solid transparent;
    border-top-width: 0;
    border-bottom-color: rgb(212, 221, 226);
  }

  ${({ isDropped }) =>
    isDropped &&
    css`
      opacity: 1;
      visibility: visible;
      transform: translate(-50%, 0);
      left: 50%;
    `};
`;

const Ul = styled.ul`
  & > li {
    margin-bottom: 10px;
  }

  & > li:first-of-type {
    margin-top: 10px;
  }

  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Li = styled.li`
  &:hover {
    background-color: rgb(0, 38, 90);
    color: rgb(255, 255, 255);
    width: 100%;
  }
`;

const LinkWrapper = styled.a`
  font-size: 12px;
  text-decoration: none;
  font-family: Pretendard;
  font-style: normal;
  color: rgb(0, 38, 90);
  cursor: pointer;

  &:hover {
    color: ${Colors.white};
  }
`;

const DropdownMenu = () => {
  const [dropIsOpen, dropRef, dropHandler] = useDetectClose(false);
  const [isOpen, setIsOpen] = useState(0);

  const onClickButton = (idx) => {
    setIsOpen(idx);
  };

  return (
    <Wrapper>
      {isOpen === 1 && (
        <Modal1
          open={isOpen}
          onClose={() => {
            setIsOpen(0);
          }}
        />
      )}
      {isOpen === 2 && (
        <Modal2
          open={isOpen}
          onClose={() => {
            setIsOpen(0);
          }}
        />
      )}
      {isOpen === 3 && (
        <Modal3
          open={isOpen}
          onClose={() => {
            setIsOpen(0);
          }}
        />
      )}
      <DropdownContainer>
        <DropdownButton onClick={dropHandler} ref={dropRef}>
          소비자중심경영
        </DropdownButton>
        <Menu isDropped={dropIsOpen}>
          <Ul>
            <Li>
              <LinkWrapper onClick={() => onClickButton(1)}>
                경영가치/가치체계
              </LinkWrapper>
            </Li>
            <Li>
              <LinkWrapper onClick={() => onClickButton(2)}>선포문</LinkWrapper>
            </Li>
            <Li>
              <LinkWrapper onClick={() => onClickButton(3)}>
                실천선언문
              </LinkWrapper>
            </Li>
          </Ul>
        </Menu>
      </DropdownContainer>
    </Wrapper>
  );
};

export default DropdownMenu;
