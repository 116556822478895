import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Colors from "../assets/theme/colors";

const DropDown = styled.div`
  margin-left: 320px;
  display: relative;

  select {
    position: relative;
    width: 150px;
    height: 20px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: ${Colors.Primary_Navy};
    color: white;
    opacity: 0.8;
    font-size: 12px;
  }

  @media all and (max-width: 1199px) {
    margin: 8px 0 -12px 0;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
  }
`;

export const DropDownLink = () => {
  const [Selected, setSelected] = useState("");

  useEffect(() => {
    if (Selected) window.open(Selected);
  }, [Selected]);

  const handleSelect = (e) => {
    setSelected(e.target.value);
  };

  return (
    <DropDown onChange={handleSelect} value={Selected}>
      <select name="none">
        <option value="" selected>
          관련사이트 바로가기
        </option>
        <optgroup label="소비자기본법 및 시행령">
          <option value="https://www.law.go.kr/법령/소비자기본법">
            소비자기본법 법률
          </option>
          <option value="https://www.law.go.kr/법령/소비자기본법시행령">
            소비자기본법 시행령
          </option>
        </optgroup>
        <optgroup label="도시가스사업법">
          <option value="https://www.law.go.kr/법령/도시가스사업법">
            도시가스사업법 법률
          </option>
          <option value="https://www.law.go.kr/법령/도시가스사업법시행령">
            도시가스사업법 시행령
          </option>
          <option value="https://www.law.go.kr/법령/도시가스사업법시행규칙">
            도시가스사업법 시행규칙
          </option>
        </optgroup>
      </select>
    </DropDown>
  );
};

export default DropDownLink;
