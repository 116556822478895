import React from 'react'
import styled from 'styled-components'

import Header from './components/Header'
import Contents from './components/Contents'
import Footer from './components/Footer'

const Wrap = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 375px;
  overflow-x: hidden;
`

function App() {
  return (
    <Wrap>
      <Header />
      <Contents />
      <Footer />
    </Wrap>
  )
}

export default App
