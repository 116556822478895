import React, { useState } from 'react'
import styled from 'styled-components'
import Colors from '../assets/theme/colors'
import Styles from '../assets/theme/styles'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import CustomMapSilde from './CustomMapSlide'

import { SITEMAP } from '../constant'

const SliderWrap = styled.div`

  @media all and (min-width: 1200px) {
    margin-top: 140px;
    width: 872px;
    height: 503px;
  }

  @media all and (max-width: 1199px) {
    margin-top: 16px;
    width: 333px;
    height: 403px;
  }
`

const WebTabWrap = styled.div`
  height: 37px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;

  &>div:nth-child(1) {
    color: ${props=>props.active === 0 ? Colors.white : 'rgba(0, 0, 0, 0.6)'};
    font-weight: ${props=>props.active === 0 ? 700: 400};
    background-color: ${props=>props.active === 0 ? Colors.Secondary_Blue : ''};
    box-shadow: ${props=>props.active === 0 ? '0px 6px 12px rgba(21, 69, 175, 0.16)' : ''};
    border-radius: ${props=>props.active === 0 ? '8px' : ''};
    
  }
  &>div:nth-child(2) {
    color: ${props=>props.active === 1 ? Colors.white : 'rgba(0, 0, 0, 0.6)'};
    font-weight: ${props=>props.active === 1 ? 700: 400};
    background-color: ${props=>props.active === 1 ? Colors.Secondary_Blue : ''};
    box-shadow: ${props=>props.active === 1 ? '0px 6px 12px rgba(21, 69, 175, 0.16)' : ''};
    border-radius: ${props=>props.active === 1 ? '8px' : ''};
  }
  &>div:nth-child(3) {
    color: ${props=>props.active === 2 ? Colors.white : 'rgba(0, 0, 0, 0.6)'};
    font-weight: ${props=>props.active === 2 ? 700: 400};
    background-color: ${props=>props.active === 2 ? Colors.Secondary_Blue : ''};
    box-shadow: ${props=>props.active === 2 ? '0px 6px 12px rgba(21, 69, 175, 0.16)' : ''};
    border-radius: ${props=>props.active === 2 ? '8px' : ''};
  }
  &>div:nth-child(4) {
    color: ${props=>props.active === 3 ? Colors.white : 'rgba(0, 0, 0, 0.6)'};
    font-weight: ${props=>props.active === 3 ? 700: 400};
    background-color: ${props=>props.active === 3 ? Colors.Secondary_Blue : ''};
    box-shadow: ${props=>props.active === 3 ? '0px 6px 12px rgba(21, 69, 175, 0.16)' : ''};
    border-radius: ${props=>props.active === 3 ? '8px' : ''};
  }
  &>div:nth-child(5) {
    color: ${props=>props.active === 4 ? Colors.white : 'rgba(0, 0, 0, 0.6)'};
    font-weight: ${props=>props.active === 4 ? 700: 400};
    background-color: ${props=>props.active === 4 ? Colors.Secondary_Blue : ''};
    box-shadow: ${props=>props.active === 4 ? '0px 6px 12px rgba(21, 69, 175, 0.16)' : ''};
    border-radius: ${props=>props.active === 4 ? '8px' : ''};
  }

  @media all and (min-width: 1200px) {
    width: 750px;
  }

  @media all and (max-width: 1199px) {
    display: none;
  }
`

const MobileTabWrap = styled.div`
  height: 37px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;

  &>div:nth-child(1) {
    color: ${props=>props.active === 0 ? Colors.white : 'rgba(0, 0, 0, 0.6)'};
    font-weight: ${props=>props.active === 0 ? 700: 400};
    background-color: ${props=>props.active === 0 ? Colors.Secondary_Blue : ''};
    box-shadow: ${props=>props.active === 0 ? '0px 6px 12px rgba(21, 69, 175, 0.16)' : ''};
    border-radius: ${props=>props.active === 0 ? '8px' : ''};
    
  }
  &>div:nth-child(2) {
    color: ${props=>props.active === 1 ? Colors.white : 'rgba(0, 0, 0, 0.6)'};
    font-weight: ${props=>props.active === 1 ? 700: 400};
    background-color: ${props=>props.active === 1 ? Colors.Secondary_Blue : ''};
    box-shadow: ${props=>props.active === 1 ? '0px 6px 12px rgba(21, 69, 175, 0.16)' : ''};
    border-radius: ${props=>props.active === 1 ? '8px' : ''};
  }
  &>div:nth-child(3) {
    color: ${props=>props.active === 2 ? Colors.white : 'rgba(0, 0, 0, 0.6)'};
    font-weight: ${props=>props.active === 2 ? 700: 400};
    background-color: ${props=>props.active === 2 ? Colors.Secondary_Blue : ''};
    box-shadow: ${props=>props.active === 2 ? '0px 6px 12px rgba(21, 69, 175, 0.16)' : ''};
    border-radius: ${props=>props.active === 2 ? '8px' : ''};
  }
  &>div:nth-child(4) {
    color: ${props=>props.active === 3 ? Colors.white : 'rgba(0, 0, 0, 0.6)'};
    font-weight: ${props=>props.active === 3 ? 700: 400};
    background-color: ${props=>props.active === 3 ? Colors.Secondary_Blue : ''};
    box-shadow: ${props=>props.active === 3 ? '0px 6px 12px rgba(21, 69, 175, 0.16)' : ''};
    border-radius: ${props=>props.active === 3 ? '8px' : ''};
  }
  &>div:nth-child(5) {
    color: ${props=>props.active === 4 ? Colors.white : 'rgba(0, 0, 0, 0.6)'};
    font-weight: ${props=>props.active === 4 ? 700: 400};
    background-color: ${props=>props.active === 4 ? Colors.Secondary_Blue : ''};
    box-shadow: ${props=>props.active === 4 ? '0px 6px 12px rgba(21, 69, 175, 0.16)' : ''};
    border-radius: ${props=>props.active === 4 ? '8px' : ''};
  }

  @media all and (min-width: 1200px) {
    display: none;
  }

  @media all and (max-width: 1199px) {
    width: 281px;
  }
`

const Text = styled(Styles.Text)`
  cursor: pointer;
  height: 21px;
  align-items: center;
  text-align: center;
  font-size: 14px;
  line-height: 21px;

  @media all and (min-width: 1200px) {
    width: 92px;
    padding: 8px 24px 8px 24px;
  }

  @media all and (max-width: 1199px) {
    width: 29px;
    padding: 8px 12px 8px 12px;
  }
`

const CsTabs = () => {
  const [value, setValue] = useState({
    key: SITEMAP.INFO[0].key,
    tab: 0,
    lat: SITEMAP.INFO[0].lat,
    lng: SITEMAP.INFO[0].lng,
    address: SITEMAP.INFO[0].address
  })


  const handleTabChange = (tab, latitude, longitude, address, id) => {
    setValue({...value, 
      key: id,
      tab: tab,
      lat: latitude,
      lng: longitude,
      address: address
    })
  }

  return (
    <SliderWrap>
      <WebTabWrap active={value.tab}>
        {SITEMAP.INFO.map((info, index) => (
          <Text
            key={`sitemap-info-${index}`}
            onClick={() => {
              handleTabChange(index, info.lat, info.lng, info.address, info.key)
            }}>
            {info.title}
          </Text>
        ))}
      </WebTabWrap>
      <MobileTabWrap active={value.tab}>
        {SITEMAP.M_INFO.map((info, index) => (
          <Text
            key={`sitemap-minfo-${index}`}
            onClick={() => {
              handleTabChange(index, info.lat, info.lng, info.address, info.key)
            }}>
            {info.title}
          </Text>
        ))}
      </MobileTabWrap>
      <CustomMapSilde 
        id={value.key}
        latitude={value.lat}
        longitude= {value.lng}
        address={value.address}
      />
    </SliderWrap>
  )
}

export default CsTabs